import React, { useState } from 'react';

import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';

import img from '../../img/AvadCars/img.png'

import { useTranslation } from "react-i18next";

import Fade from 'react-reveal/Fade'

import button from '../../img/button.svg'

function Main() {

  const { t } = useTranslation();

  const [swiper, setSwiper] = useState({});

  return (
    <>
        <div className='max-w-[1280px] mx-auto mt-[80px] xl:mt-[20px] xl:h-[710px] relative'>
            <div className='flex flex-col xl:hidden mx-[20px] '>
                <Fade bottom delay={200}><h1 className='neutral text-[32px] xl:text-[54px] mt-[60px]'>{t('cars.1')}</h1></Fade>
                <Fade bottom delay={400}><p className='inter text-[24px] xl:text-[24px] text-[#000000B2]'>{t('cars.2')}</p></Fade>
                <a href=""><div className='flex gap-[4px] mt-[20px] xl:mt-[40px]'>
                    <div className='buttoncars'>
                        <p className='text-white inter text-[16px] xl:text-[20px]'>{t('cars.3')}</p>
                    </div>
                    <div className='butcar flex items-center px-[20px]'>
                        <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path id="Arrow 1" d="M17.6903 5.41784C17.9211 5.18707 17.9211 4.81293 17.6903 4.58216L13.9298 0.821642C13.6991 0.590878 13.3249 0.590878 13.0941 0.821642C12.8634 1.05241 12.8634 1.42655 13.0941 1.65731L16.4368 5L13.0941 8.34269C12.8634 8.57345 12.8634 8.94759 13.0941 9.17836C13.3249 9.40912 13.6991 9.40912 13.9298 9.17836L17.6903 5.41784ZM0.727051 5.59091H17.2725V4.40909H0.727051V5.59091Z" fill="black"/>
                        </svg>
                    </div>
                </div></a>
            </div>
            <div className='relative'>
                <div className='bg-white w-[25vw] h-[25vw] absolute inset-0 xl:hidden block'></div>
                <img src={img} alt="" className='w-full h-[40vh] xl:h-full object-cover mt-[80px] xl:mt-0'/>
                <p className='xl:absolute left-[25px] bottom-[25px] inter text-[14px] xl:text-[16px] mt-[16px] xl:mt-0 mx-[20px] xl:mx-0'>{t('cars.4')}</p>
            </div>
            <div className='h-[341px] bg-white w-[55%] absolute inset-0 hidden xl:block'>
                <Fade bottom delay={200}><h1 className='neutral text-[32px] xl:text-[54px] mt-[60px]'>{t('cars.1')}</h1></Fade>
                <Fade bottom delay={400}><p className='inter text-[24px] xl:text-[24px] text-[#000000B2]'>{t('cars.2')}</p></Fade>
                <a href=""><div className='flex gap-[4px] mt-[20px] xl:mt-[40px]'>
                    <div className='buttoncars'>
                        <p className='text-white inter text-[16px] xl:text-[20px]'>{t('cars.3')}</p>
                    </div>
                    <div className='butcar flex items-center px-[20px]'>
                        <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path id="Arrow 1" d="M17.6903 5.41784C17.9211 5.18707 17.9211 4.81293 17.6903 4.58216L13.9298 0.821642C13.6991 0.590878 13.3249 0.590878 13.0941 0.821642C12.8634 1.05241 12.8634 1.42655 13.0941 1.65731L16.4368 5L13.0941 8.34269C12.8634 8.57345 12.8634 8.94759 13.0941 9.17836C13.3249 9.40912 13.6991 9.40912 13.9298 9.17836L17.6903 5.41784ZM0.727051 5.59091H17.2725V4.40909H0.727051V5.59091Z" fill="black"/>
                        </svg>
                    </div>
                </div></a>
            </div>
        </div>
        <div className='max-w-[1280px] xl:mx-auto mx-[20px] mt-[80px] xl:mt-[160px]'>
            <div className='block xl:hidden'>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={40}
                    loop={true}
                    className=''
                    onInit={(e) => {
                        setSwiper(e);
                    }}
                    breakpoints={{
                        1: {
                            slidesPerView: 1,
                        },
                        680: {
                            slidesPerView: 2,
                        },
                    }}
                >
                    <SwiperSlide>
                        <div className='flex gap-[20px] border-b pb-[20px]'>
                            <svg className='flex-shrink-0 mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 34 34" fill="none">
                                <rect x="0.425" y="0.425" width="33.15" height="33.15" stroke="black" stroke-width="0.85"/>
                                <path d="M25.8005 17.3005C25.9665 17.1346 25.9665 16.8655 25.8005 16.6995L23.0958 13.9948C22.9299 13.8288 22.6608 13.8288 22.4948 13.9948C22.3288 14.1608 22.3288 14.4299 22.4948 14.5958L24.899 17L22.4948 19.4042C22.3288 19.5701 22.3288 19.8392 22.4948 20.0052C22.6608 20.1712 22.9299 20.1712 23.0958 20.0052L25.8005 17.3005ZM8.5 17.425L25.5 17.425L25.5 16.575L8.5 16.575L8.5 17.425Z" fill="black"/>
                            </svg>
                            <div>
                                <p className='inter text-[20px] xl:text-[24px] font-[500]'>{t('cars.5')}</p>
                                <p className='mt-[12px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2]'>{t('cars.6')}</p>
                            </div>
                        </div>
                        <div className='flex gap-[20px] border-b py-[20px]'>
                            <svg className='flex-shrink-0 mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 34 34" fill="none">
                                <rect x="0.425" y="0.425" width="33.15" height="33.15" stroke="black" stroke-width="0.85"/>
                                <path d="M25.8005 17.3005C25.9665 17.1346 25.9665 16.8655 25.8005 16.6995L23.0958 13.9948C22.9299 13.8288 22.6608 13.8288 22.4948 13.9948C22.3288 14.1608 22.3288 14.4299 22.4948 14.5958L24.899 17L22.4948 19.4042C22.3288 19.5701 22.3288 19.8392 22.4948 20.0052C22.6608 20.1712 22.9299 20.1712 23.0958 20.0052L25.8005 17.3005ZM8.5 17.425L25.5 17.425L25.5 16.575L8.5 16.575L8.5 17.425Z" fill="black"/>
                            </svg>
                            <div>
                                <p className='inter text-[20px] xl:text-[24px] font-[500]'>{t('cars.7')}</p>
                                <p className='mt-[12px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2]'>{t('cars.8')}</p>
                            </div>
                        </div>
                        <div className='flex gap-[20px] border-b py-[20px]'>
                            <svg className='flex-shrink-0 mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 34 34" fill="none">
                                <rect x="0.425" y="0.425" width="33.15" height="33.15" stroke="black" stroke-width="0.85"/>
                                <path d="M25.8005 17.3005C25.9665 17.1346 25.9665 16.8655 25.8005 16.6995L23.0958 13.9948C22.9299 13.8288 22.6608 13.8288 22.4948 13.9948C22.3288 14.1608 22.3288 14.4299 22.4948 14.5958L24.899 17L22.4948 19.4042C22.3288 19.5701 22.3288 19.8392 22.4948 20.0052C22.6608 20.1712 22.9299 20.1712 23.0958 20.0052L25.8005 17.3005ZM8.5 17.425L25.5 17.425L25.5 16.575L8.5 16.575L8.5 17.425Z" fill="black"/>
                            </svg>
                            <div>
                                <p className='inter text-[20px] xl:text-[24px] font-[500]'>{t('cars.9')}</p>
                                <p className='mt-[12px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2]'>{t('cars.10')}</p>
                            </div>
                        </div>
                        <div className='flex gap-[20px] border-b py-[20px]'>
                            <svg className='flex-shrink-0 mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 34 34" fill="none">
                                <rect x="0.425" y="0.425" width="33.15" height="33.15" stroke="black" stroke-width="0.85"/>
                                <path d="M25.8005 17.3005C25.9665 17.1346 25.9665 16.8655 25.8005 16.6995L23.0958 13.9948C22.9299 13.8288 22.6608 13.8288 22.4948 13.9948C22.3288 14.1608 22.3288 14.4299 22.4948 14.5958L24.899 17L22.4948 19.4042C22.3288 19.5701 22.3288 19.8392 22.4948 20.0052C22.6608 20.1712 22.9299 20.1712 23.0958 20.0052L25.8005 17.3005ZM8.5 17.425L25.5 17.425L25.5 16.575L8.5 16.575L8.5 17.425Z" fill="black"/>
                            </svg>
                            <div>
                                <p className='inter text-[20px] xl:text-[24px] font-[500]'>{t('cars.11')}</p>
                                <p className='mt-[12px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2]'>{t('cars.12')}</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='flex gap-[20px] border-b pb-[20px]'>
                            <svg className='flex-shrink-0 mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 34 34" fill="none">
                                <rect x="0.425" y="0.425" width="33.15" height="33.15" stroke="black" stroke-width="0.85"/>
                                <path d="M25.8005 17.3005C25.9665 17.1346 25.9665 16.8655 25.8005 16.6995L23.0958 13.9948C22.9299 13.8288 22.6608 13.8288 22.4948 13.9948C22.3288 14.1608 22.3288 14.4299 22.4948 14.5958L24.899 17L22.4948 19.4042C22.3288 19.5701 22.3288 19.8392 22.4948 20.0052C22.6608 20.1712 22.9299 20.1712 23.0958 20.0052L25.8005 17.3005ZM8.5 17.425L25.5 17.425L25.5 16.575L8.5 16.575L8.5 17.425Z" fill="black"/>
                            </svg>
                            <div>
                                <p className='inter text-[20px] xl:text-[24px] font-[500]'>{t('cars.13')}</p>
                                <p className='mt-[12px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2]'>{t('cars.14')}</p>
                            </div>
                        </div>
                        <div className='flex gap-[20px] border-b py-[20px]'>
                            <svg className='flex-shrink-0 mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 34 34" fill="none">
                                <rect x="0.425" y="0.425" width="33.15" height="33.15" stroke="black" stroke-width="0.85"/>
                                <path d="M25.8005 17.3005C25.9665 17.1346 25.9665 16.8655 25.8005 16.6995L23.0958 13.9948C22.9299 13.8288 22.6608 13.8288 22.4948 13.9948C22.3288 14.1608 22.3288 14.4299 22.4948 14.5958L24.899 17L22.4948 19.4042C22.3288 19.5701 22.3288 19.8392 22.4948 20.0052C22.6608 20.1712 22.9299 20.1712 23.0958 20.0052L25.8005 17.3005ZM8.5 17.425L25.5 17.425L25.5 16.575L8.5 16.575L8.5 17.425Z" fill="black"/>
                            </svg>
                            <div>
                                <p className='inter text-[20px] xl:text-[24px] font-[500]'>{t('cars.15')}</p>
                                <p className='mt-[12px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2]'>{t('cars.16')}</p>
                            </div>
                        </div>
                        <div className='flex gap-[20px] border-b py-[20px]'>
                            <svg className='flex-shrink-0 mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 34 34" fill="none">
                                <rect x="0.425" y="0.425" width="33.15" height="33.15" stroke="black" stroke-width="0.85"/>
                                <path d="M25.8005 17.3005C25.9665 17.1346 25.9665 16.8655 25.8005 16.6995L23.0958 13.9948C22.9299 13.8288 22.6608 13.8288 22.4948 13.9948C22.3288 14.1608 22.3288 14.4299 22.4948 14.5958L24.899 17L22.4948 19.4042C22.3288 19.5701 22.3288 19.8392 22.4948 20.0052C22.6608 20.1712 22.9299 20.1712 23.0958 20.0052L25.8005 17.3005ZM8.5 17.425L25.5 17.425L25.5 16.575L8.5 16.575L8.5 17.425Z" fill="black"/>
                            </svg>
                            <div>
                                <p className='inter text-[20px] xl:text-[24px] font-[500]'>{t('cars.17')}</p>
                                <p className='mt-[12px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2]'>{t('cars.18')}</p>
                            </div>
                        </div>
                        <div className='flex gap-[20px] border-b py-[20px]'>
                            <svg className='flex-shrink-0 mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 34 34" fill="none">
                                <rect x="0.425" y="0.425" width="33.15" height="33.15" stroke="black" stroke-width="0.85"/>
                                <path d="M25.8005 17.3005C25.9665 17.1346 25.9665 16.8655 25.8005 16.6995L23.0958 13.9948C22.9299 13.8288 22.6608 13.8288 22.4948 13.9948C22.3288 14.1608 22.3288 14.4299 22.4948 14.5958L24.899 17L22.4948 19.4042C22.3288 19.5701 22.3288 19.8392 22.4948 20.0052C22.6608 20.1712 22.9299 20.1712 23.0958 20.0052L25.8005 17.3005ZM8.5 17.425L25.5 17.425L25.5 16.575L8.5 16.575L8.5 17.425Z" fill="black"/>
                            </svg>
                            <div>
                                <p className='inter text-[20px] xl:text-[24px] font-[500]'>{t('cars.25')}</p>
                                <p className='mt-[12px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2]'>{t('cars.26')}</p>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
                <div className='flex justify-between mt-[20px]'>
                    <img
                    onClick={() => swiper.slidePrev()}
                    src={button}
                    className='w-[30px] h-[30px] rotate-180'
                    />
                    <img
                    onClick={() => swiper.slideNext()}
                    src={button}
                    className='w-[30px] h-[30px]'
                    />
                </div>   
            </div>
            <div className='xl:flex justify-between hidden'>
                <div className='w-[600px]'>
                    <div className='flex gap-[70px] border-b pb-[30px]'>
                        <svg className='flex-shrink-0 mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                            <rect x="0.425" y="0.425" width="33.15" height="33.15" stroke="black" stroke-width="0.85"/>
                            <path d="M25.8005 17.3005C25.9665 17.1346 25.9665 16.8655 25.8005 16.6995L23.0958 13.9948C22.9299 13.8288 22.6608 13.8288 22.4948 13.9948C22.3288 14.1608 22.3288 14.4299 22.4948 14.5958L24.899 17L22.4948 19.4042C22.3288 19.5701 22.3288 19.8392 22.4948 20.0052C22.6608 20.1712 22.9299 20.1712 23.0958 20.0052L25.8005 17.3005ZM8.5 17.425L25.5 17.425L25.5 16.575L8.5 16.575L8.5 17.425Z" fill="black"/>
                        </svg>
                        <div>
                            <p className='inter text-[20px] xl:text-[24px] font-[500]'>{t('cars.5')}</p>
                            <p className='mt-[12px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2]'>{t('cars.6')}</p>
                        </div>
                    </div>
                    <div className='flex gap-[70px] border-b py-[30px]'>
                        <svg className='flex-shrink-0 mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                            <rect x="0.425" y="0.425" width="33.15" height="33.15" stroke="black" stroke-width="0.85"/>
                            <path d="M25.8005 17.3005C25.9665 17.1346 25.9665 16.8655 25.8005 16.6995L23.0958 13.9948C22.9299 13.8288 22.6608 13.8288 22.4948 13.9948C22.3288 14.1608 22.3288 14.4299 22.4948 14.5958L24.899 17L22.4948 19.4042C22.3288 19.5701 22.3288 19.8392 22.4948 20.0052C22.6608 20.1712 22.9299 20.1712 23.0958 20.0052L25.8005 17.3005ZM8.5 17.425L25.5 17.425L25.5 16.575L8.5 16.575L8.5 17.425Z" fill="black"/>
                        </svg>
                        <div>
                            <p className='inter text-[20px] xl:text-[24px] font-[500]'>{t('cars.7')}</p>
                            <p className='mt-[12px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2]'>{t('cars.8')}</p>
                        </div>
                    </div>
                    <div className='flex gap-[70px] border-b py-[30px]'>
                        <svg className='flex-shrink-0 mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                            <rect x="0.425" y="0.425" width="33.15" height="33.15" stroke="black" stroke-width="0.85"/>
                            <path d="M25.8005 17.3005C25.9665 17.1346 25.9665 16.8655 25.8005 16.6995L23.0958 13.9948C22.9299 13.8288 22.6608 13.8288 22.4948 13.9948C22.3288 14.1608 22.3288 14.4299 22.4948 14.5958L24.899 17L22.4948 19.4042C22.3288 19.5701 22.3288 19.8392 22.4948 20.0052C22.6608 20.1712 22.9299 20.1712 23.0958 20.0052L25.8005 17.3005ZM8.5 17.425L25.5 17.425L25.5 16.575L8.5 16.575L8.5 17.425Z" fill="black"/>
                        </svg>
                        <div>
                            <p className='inter text-[20px] xl:text-[24px] font-[500]'>{t('cars.9')}</p>
                            <p className='mt-[12px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2]'>{t('cars.10')}</p>
                        </div>
                    </div>
                    <div className='flex gap-[70px] border-b py-[30px]'>
                        <svg className='flex-shrink-0 mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                            <rect x="0.425" y="0.425" width="33.15" height="33.15" stroke="black" stroke-width="0.85"/>
                            <path d="M25.8005 17.3005C25.9665 17.1346 25.9665 16.8655 25.8005 16.6995L23.0958 13.9948C22.9299 13.8288 22.6608 13.8288 22.4948 13.9948C22.3288 14.1608 22.3288 14.4299 22.4948 14.5958L24.899 17L22.4948 19.4042C22.3288 19.5701 22.3288 19.8392 22.4948 20.0052C22.6608 20.1712 22.9299 20.1712 23.0958 20.0052L25.8005 17.3005ZM8.5 17.425L25.5 17.425L25.5 16.575L8.5 16.575L8.5 17.425Z" fill="black"/>
                        </svg>
                        <div>
                            <p className='inter text-[20px] xl:text-[24px] font-[500]'>{t('cars.11')}</p>
                            <p className='mt-[12px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2]'>{t('cars.12')}</p>
                        </div>
                    </div>                   
                </div>
                <div className='w-[600px]'>
                    <div className='flex gap-[70px] border-b pb-[30px]'>
                        <svg className='flex-shrink-0 mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                            <rect x="0.425" y="0.425" width="33.15" height="33.15" stroke="black" stroke-width="0.85"/>
                            <path d="M25.8005 17.3005C25.9665 17.1346 25.9665 16.8655 25.8005 16.6995L23.0958 13.9948C22.9299 13.8288 22.6608 13.8288 22.4948 13.9948C22.3288 14.1608 22.3288 14.4299 22.4948 14.5958L24.899 17L22.4948 19.4042C22.3288 19.5701 22.3288 19.8392 22.4948 20.0052C22.6608 20.1712 22.9299 20.1712 23.0958 20.0052L25.8005 17.3005ZM8.5 17.425L25.5 17.425L25.5 16.575L8.5 16.575L8.5 17.425Z" fill="black"/>
                        </svg>
                        <div>
                            <p className='inter text-[20px] xl:text-[24px] font-[500]'>{t('cars.13')}</p>
                            <p className='mt-[12px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2]'>{t('cars.14')}</p>
                        </div>
                    </div>
                    <div className='flex gap-[70px] border-b py-[30px]'>
                        <svg className='flex-shrink-0 mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                            <rect x="0.425" y="0.425" width="33.15" height="33.15" stroke="black" stroke-width="0.85"/>
                            <path d="M25.8005 17.3005C25.9665 17.1346 25.9665 16.8655 25.8005 16.6995L23.0958 13.9948C22.9299 13.8288 22.6608 13.8288 22.4948 13.9948C22.3288 14.1608 22.3288 14.4299 22.4948 14.5958L24.899 17L22.4948 19.4042C22.3288 19.5701 22.3288 19.8392 22.4948 20.0052C22.6608 20.1712 22.9299 20.1712 23.0958 20.0052L25.8005 17.3005ZM8.5 17.425L25.5 17.425L25.5 16.575L8.5 16.575L8.5 17.425Z" fill="black"/>
                        </svg>
                        <div>
                            <p className='inter text-[20px] xl:text-[24px] font-[500]'>{t('cars.15')}</p>
                            <p className='mt-[12px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2]'>{t('cars.16')}</p>
                        </div>
                    </div>
                    <div className='flex gap-[70px] border-b py-[30px]'>
                        <svg className='flex-shrink-0 mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                            <rect x="0.425" y="0.425" width="33.15" height="33.15" stroke="black" stroke-width="0.85"/>
                            <path d="M25.8005 17.3005C25.9665 17.1346 25.9665 16.8655 25.8005 16.6995L23.0958 13.9948C22.9299 13.8288 22.6608 13.8288 22.4948 13.9948C22.3288 14.1608 22.3288 14.4299 22.4948 14.5958L24.899 17L22.4948 19.4042C22.3288 19.5701 22.3288 19.8392 22.4948 20.0052C22.6608 20.1712 22.9299 20.1712 23.0958 20.0052L25.8005 17.3005ZM8.5 17.425L25.5 17.425L25.5 16.575L8.5 16.575L8.5 17.425Z" fill="black"/>
                        </svg>
                        <div>
                            <p className='inter text-[20px] xl:text-[24px] font-[500]'>{t('cars.17')}</p>
                            <p className='mt-[12px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2]'>{t('cars.18')}</p>
                        </div>
                    </div>
                    <div className='flex gap-[70px] border-b py-[30px]'>
                        <svg className='flex-shrink-0 mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                            <rect x="0.425" y="0.425" width="33.15" height="33.15" stroke="black" stroke-width="0.85"/>
                            <path d="M25.8005 17.3005C25.9665 17.1346 25.9665 16.8655 25.8005 16.6995L23.0958 13.9948C22.9299 13.8288 22.6608 13.8288 22.4948 13.9948C22.3288 14.1608 22.3288 14.4299 22.4948 14.5958L24.899 17L22.4948 19.4042C22.3288 19.5701 22.3288 19.8392 22.4948 20.0052C22.6608 20.1712 22.9299 20.1712 23.0958 20.0052L25.8005 17.3005ZM8.5 17.425L25.5 17.425L25.5 16.575L8.5 16.575L8.5 17.425Z" fill="black"/>
                        </svg>
                        <div>
                            <p className='inter text-[20px] xl:text-[24px] font-[500]'>{t('cars.25')}</p>
                            <p className='mt-[12px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2]'>{t('cars.26')}</p>
                        </div>
                    </div>                   
                </div>
            </div>
        </div>
    </>
  );
}

export default Main;
