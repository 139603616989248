import React, { useState } from 'react';

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from 'swiper/modules';

import button from '../../img/button.svg'

import Fade from "react-reveal/Fade"

import 'swiper/css';

import feedback1 from '../../img/feedback1.png'
import feedback2 from '../../img/feedback2.png'
import feedback3 from '../../img/feedback3.png'
import feedback4 from '../../img/feedback4.png'
import feedback5 from '../../img/feedback5.png'

import { useTranslation } from "react-i18next";

const feedbackData = [
    {
        id: 1,
        name: 'Sarah Bennett',
        feedback: 'home.4',
        image: feedback1
    },
    {
        id: 2,
        name: 'Richard Anderson',
        feedback: 'home.5',
        image: feedback2
    },
    {
        id: 3,
        name: 'Olivia Martinez',
        feedback: 'home.6',
        image: feedback3
    },
    {
        id: 4,
        name: 'Christopher Nguyen',
        feedback: 'home.7',
        image: feedback4
    },
    {
        id: 5,
        name: 'Jessica Taylor',
        feedback: 'home.8',
        image: feedback5
    },
];

function FeedBack() {
  const [swiper, setSwiper] = useState({});
  
  const { t } = useTranslation();

  return (
    <>
        <div className='mt-[120px] xl:mt-[180px] max-w-[1280px] mx-auto xl:pb-[180px] pb-[120px]'>
            <div className='flex'>
                <div>
                    <div className='bg-black h-[1px] w-[178px] xl:w-[280px] mx-[20px] xl:ml-0'></div>
                    <h1 className='neutral text-[16px] xl:text-[24px] mt-[13px] mx-[20px] xl:ml-0'>· {t('home.9')}</h1>
                </div>
                <div className='flex w-[60px] ml-auto xl:mr-[20px] mr-[40px]'>
                    <img
                    onClick={() => swiper.slidePrev()}
                    src={button}
                    className='w-[30px] h-[30px] rotate-180'
                    />
                    <img
                    onClick={() => swiper.slideNext()}
                    src={button}
                    className='w-[30px] h-[30px] mx-[20px]'
                    />
                </div>               
            </div>
            <div>
            <Swiper
                modules={[Navigation, Autoplay]}
                slidesPerView={2}
                spaceBetween={10}
                loop={true}
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                className='mt-[30px] xl:mt-[60px]'
                onInit={(e) => {
                    setSwiper(e);
                }}
                breakpoints={{
                    1: {
                        slidesPerView: 1,
                      },
                    680: {
                      slidesPerView: 2,
                    },
                  }}
            >
            {feedbackData.map((slide) => (
                <SwiperSlide key={slide.id}>
                    <div className='xl:w-[635px] xl:h-[325px] h-[350px] border mx-[20px] xl:mx-0'>
                        <img src={slide.image} alt="" className='mt-[20px] xl:mx-[40px] mx-[20px]'/>
                        <h1 className='inter text-[16px] xl:text-[20px] font-medium xl:mx-[40px] mx-[20px] mt-[20px]'>{slide.name}</h1>
                        <p className='text-[12px] xl:text-[16px] textcolor xl:mx-[40px] mx-[20px] mt-[12px] xl:mr-[50px]'>{t(slide.feedback)}</p>
                    </div>
                </SwiperSlide>
            ))}
            </Swiper>
            </div>
        </div>
    </>
  );
}

export default FeedBack;
