import React from 'react';

import { useTranslation } from "react-i18next";

import i1 from '../../img/About/i1.jpg'
import i2 from '../../img/About/i2.jpg'

function AboutUSUS() {

  const { t } = useTranslation();

  return (
    <>
        <div className='relative mt-[80px] xl:mt-[180px] mx-[20px] max-w-[1280px] xl:mx-auto'>
            <div className='flex flex-col xl:flex-row justify-between'>
                <div>
                    <div className='bg-black h-[1px] w-[178px] xl:w-[280px]'></div>
                    <h1 className='neutral text-[16px] xl:text-[24px] mt-[13px]'>· {t('about.5')}</h1>
                    <p className='inter textcolor text-[12px] xl:text-[16px] xl:w-[560px] mt-[20px] xl:mt-[40px]'>{t('about.6')}</p>
                    <p className='inter textcolor text-[12px] xl:text-[16px] xl:w-[560px] mt-[20px]'>{t('about.7')}</p>
                    <p className='inter textcolor text-[12px] xl:text-[16px] xl:w-[560px] mt-[20px]'>{t('about.8')}</p>
                </div>
                <div className='mt-[20px] xl:mt-0'>
                    <img src={i1} alt="" />
                </div>
            </div>
            <div className='flex flex-col xl:flex-row-reverse justify-between mt-[20px] xl:mt-0'>
                <div>
                    <p className='inter textcolor text-[12px] xl:text-[16px] xl:w-[560px] mt-[20px] xl:mt-[100px]'>{t('about.9')}</p>
                    <p className='inter textcolor text-[12px] xl:text-[16px] xl:w-[560px] mt-[20px]'>{t('about.10')}</p>
                    <p className='inter textcolor text-[12px] xl:text-[16px] xl:w-[560px] mt-[20px]'>{t('about.11')}</p>
                </div>
                <div className='mt-[20px] xl:mt-0'>
                    <img src={i2} alt="" className='xl:mt-[-160px]'/>
                </div>
            </div>
        </div>
    </>
  );
}

export default AboutUSUS;
