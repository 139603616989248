import React from 'react';

import yoursucess from '../../img/yoursucess.jpg'

import Fade from 'react-reveal/Fade'

import { useTranslation } from "react-i18next";

function YourSucess() {

  const { t } = useTranslation();

  return (
    <>
        <div className='mt-[120px] xl:mt-[180px] max-w-[1280px] mx-auto relative overflow-hidden'>
            <img src={yoursucess} alt="" className='object-cover h-[576px] xl:h-auto'/>
            <Fade left delay={200}><div className='absolute left-0 top-[0px] w-auto mx-[20px] xl:mx-0 xl:w-[746px] h-auto flex bg-white'>
                <div className='xl:mx-[146px] pb-[34px] mx-[20px]'>
                    <h1 className='inter text-[16px] xl:text-[24px] mt-[46px]'>{t('home.44')}</h1>
                    <h1 className='neutral text-[24px] xl:text-[36px] mt-[13px]'>{t('home.45')}</h1>
                </div>
            </div></Fade>
        </div>
    </>
  );
}

export default YourSucess;
