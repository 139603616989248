import React, { useState } from 'react';

import { useTranslation } from "react-i18next";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from 'swiper/modules';

import 'swiper/css';

import s1 from '../../img/Services/sw/sw1.png'
import s2 from '../../img/Services/sw/sw2.png'
import s3 from '../../img/Services/sw/sw3.png'
import s4 from '../../img/Services/sw/sw4.png'
import s5 from '../../img/Services/sw/sw5.png'
import s6 from '../../img/Services/sw/sw6.png'
import s7 from '../../img/Services/sw/sw7.png'

const offerings = [
    {
      title: 'services.5',
      description: 'services.6',
      imgSrc: s1,
    },
    {
        title: 'services.7',
        description: 'services.8',
        imgSrc: s2,
    },
    {
        title: 'services.18',
        description: 'services.19',
        imgSrc: s3,
    },
    {
        title: 'services.20',
        description: 'services.21',
        imgSrc: s4,
    },
    {
        title: 'services.22',
        description: 'services.23',
        imgSrc: s5,
    },
    {
        title: 'services.24',
        description: 'services.25',
        imgSrc: s6,
    },
    {
        title: 'services.26',
        description: 'services.27',
        imgSrc: s7,
    },
  ];

function WhatWeOffer() {

  const { t } = useTranslation();

  const [swiper, setSwiper] = useState({});

  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <>
        <div className='max-w-[1280px] mx-[20px] xl:mx-auto mt-[80px] xl:mt-[200px] relative'>
            <div className='flex flex-col xl:flex-row justify-between'>
                <div>
                    <div className='bg-black h-[1px] w-[178px] xl:w-[280px]'></div>
                    <h1 className='neutral text-[16px] xl:text-[24px] mt-[13px]'>· {t('services.4')}</h1>
                </div>
                <div className='flex xl:w-[550px] justify-between items-center mt-[20px] xl:mt-0'>
                    <div>
                        <p className='inter font-[300] border rounded-[20px] py-[3px] px-[6px]'>{`0${currentIndex + 1} - 0${offerings.length}`}</p>
                    </div>
                    <div className='flex gap-[20px]'>
                        <svg className='rotate-180 cursor-pointer' onClick={() => swiper.slidePrev()} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <rect x="0.5" y="0.5" width="39" height="39" stroke="black"/>
                            <path d="M30.3536 20.3536C30.5488 20.1583 30.5488 19.8417 30.3536 19.6465L27.1716 16.4645C26.9763 16.2692 26.6597 16.2692 26.4645 16.4645C26.2692 16.6597 26.2692 16.9763 26.4645 17.1716L29.2929 20L26.4645 22.8284C26.2692 23.0237 26.2692 23.3403 26.4645 23.5355C26.6597 23.7308 26.9763 23.7308 27.1716 23.5355L30.3536 20.3536ZM10 20.5L30 20.5L30 19.5L10 19.5L10 20.5Z" fill="black"/>
                        </svg>
                        <svg className='cursor-pointer' onClick={() => swiper.slideNext()} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <rect x="0.5" y="0.5" width="39" height="39" stroke="black"/>
                            <path d="M30.3536 20.3536C30.5488 20.1583 30.5488 19.8417 30.3536 19.6465L27.1716 16.4645C26.9763 16.2692 26.6597 16.2692 26.4645 16.4645C26.2692 16.6597 26.2692 16.9763 26.4645 17.1716L29.2929 20L26.4645 22.8284C26.2692 23.0237 26.2692 23.3403 26.4645 23.5355C26.6597 23.7308 26.9763 23.7308 27.1716 23.5355L30.3536 20.3536ZM10 20.5L30 20.5L30 19.5L10 19.5L10 20.5Z" fill="black"/>
                        </svg>
                    </div>  
                </div>
            </div>
            <div className="mt-[20px] xl:mt-[40px] w-full">
                <Swiper
                    navigation
                    pagination={{ clickable: true }}
                    slidesPerView={1}
                    spaceBetween={10}
                    onInit={(e) => {
                        setSwiper(e);
                    }}
                    onSlideChange={(swiper) => {
                        setCurrentIndex(swiper.activeIndex); 
                    }}
                >
                    {offerings.map((offering, index) => (
                        <SwiperSlide key={index}>
                            <div className='flex flex-col xl:flex-row justify-between gap-[20px] xl:gap-[60px]'>
                                <img src={offering.imgSrc} alt="" />
                                <div>
                                    <p className='inter text-[16px] xl:text-[24px] font-[500]'>{t(offering.title)}</p>
                                    <p className='mt-[12px] text-[#000000B2] inter font-[300] text-[14px] xl:text-[16px]'>{t(offering.description)}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    </>
  );
}

export default WhatWeOffer;
