import React from 'react';

import value1 from '../../img/ourvalues1.svg'
import value2 from '../../img/ourvalues3.svg'
import value3 from '../../img/ourvalues2.svg'
import value4 from '../../img/ourvalues4.svg'

import Fade from 'react-reveal/Fade'

import { useTranslation } from "react-i18next";

function OurValues() {

  const { t } = useTranslation();

  return (
    <>
      <div className='mt-[120px] xl:mt-[180px] max-w-[1280px] mx-auto'>
        <div className='flex xl:flex-row flex-col'>
          <div>
            <div className='bg-black h-[1px] w-[178px] xl:w-[280px] mx-[20px] xl:ml-0'></div>
            <h1 className='neutral text-[16px] xl:text-[24px] mt-[13px] mx-[20px] xl:ml-0'>· {t('home.10')}</h1>
          </div>
          <div className='flex flex-wrap xl:justify-end gap-[20px] mx-[20px] xl:mx-0 mt-[20px] xl:mt-0'>
            <Fade><div className='xl:w-[394px] w-full h-[170px] xl:h-[229px] border'>
              <div className='mt-[20px] xl:mx-[40px] mx-[20px]'>
                <p className='font-light inter text-[20px] xl:text-[24px]'>01</p>
                <div className='flex'>
                  <img src={value1} alt="" className='w-[40px] xl:w-[60px] mt-[45px] xl:mt-[85px]'/>
                  <p className='inter font-medium text-[16px] xl:text-[24px] mt-[80px] xl:mt-[125px] mx-[36px]'>{t('home.11')}</p>
                </div>
              </div>
            </div></Fade>
            <Fade delay={300}><div className='xl:w-[394px] w-full h-[170px] xl:h-[229px] border'>
              <div className='mt-[20px] xl:mx-[40px] mx-[20px]'>
                <p className='font-light inter text-[20px] xl:text-[24px]'>02</p>
                <div className='flex'>
                  <img src={value2} alt="" className='w-[40px] xl:w-[60px] mt-[45px] xl:mt-[85px]'/>
                  <p className='inter font-medium text-[16px] xl:text-[24px] mt-[80px] xl:mt-[125px] mx-[36px]'>{t('home.12')}</p>
                </div>
              </div>
            </div></Fade>
            <Fade delay={600}><div className='xl:w-[394px] w-full h-[170px] xl:h-[229px] border'>
              <div className='mt-[20px] xl:mx-[40px] mx-[20px]'>
                <p className='font-light inter text-[20px] xl:text-[24px]'>03</p>
                <div className='flex'>
                  <img src={value3} alt="" className='w-[40px] xl:w-[60px] mt-[45px] xl:mt-[85px]'/>
                  <p className='inter font-medium text-[16px] xl:text-[24px] mt-[80px] xl:mt-[125px] mx-[36px]'>{t('home.13')}</p>
                </div>
              </div>
            </div></Fade>
            <Fade delay={900}><div className='xl:w-[394px] w-full h-[170px] xl:h-[229px] border'>
              <div className='mt-[20px] xl:mx-[40px] mx-[20px]'>
                <p className='font-light inter text-[20px] xl:text-[24px]'>04</p>
                <div className='flex'>
                  <img src={value4} alt="" className='w-[40px] xl:w-[60px] mt-[45px] xl:mt-[85px]'/>
                  <p className='inter font-medium text-[16px] xl:text-[24px] mt-[80px] xl:mt-[125px] mx-[36px]'>{t('home.14')}</p>
                </div>
              </div>
            </div></Fade>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurValues;
