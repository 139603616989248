import React from 'react';

import { useTranslation } from "react-i18next";

import img from '../../img/AvadCars/image.png'

import Fade from 'react-reveal/Fade'

function Experience() {

  const { t } = useTranslation();

  return (
    <>
        <div className='max-w-[1280px] mx-auto xl:h-[640px] mt-[80px] xl:mt-[160px] relative'>
            <p className='xl:absolute inset-[20px] text-black xl:text-white inter xl:max-w-[391px] font-light mx-[20px]'>{t('cars.27')}</p>
            <img src={img} alt="" className='w-full h-[456px] xl:h-full object-cover mt-[16px] xl:mt-0'/>           
            <div className='xl:bg-white xl:h-[368px] xl:w-[805px] xl:absolute bottom-0 left-0'>
                <div className='mt-[60px]'>
                    <div className='bg-black h-[1px] w-[178px] xl:w-[280px] mx-[20px] xl:ml-0'></div>
                    <h1 className='neutral text-[16px] xl:text-[24px] mt-[13px] mx-[20px] xl:ml-0'>· {t('cars.20')}</h1>
                    <p className='textcolor inter text-[12px] xl:text-[16px] font-light xl:pr-[60px] mt-[20px] xl:mt-[40px] mx-[20px] xl:ml-0'>{t('cars.21')}</p>
                    <p className='textcolor inter text-[12px] xl:text-[16px] font-light xl:pr-[60px] mt-[20px] mx-[20px] xl:ml-0'>{t('cars.22')}</p>
                </div>
            </div>
        </div>
    </>
  );
}

export default Experience;
