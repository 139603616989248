import React, { useState, useEffect } from "react";

import logo from '../img/avad.svg'

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import '../components/header.css'

import { Link } from 'react-router-dom';

import { slide as Menu } from 'react-burger-menu'

import i18n from "i18next";
import Cookies from "js-cookie";

import { useTranslation } from "react-i18next";

import MobileMenu from "./MobileMenu";

function Header() {

  const { t } = useTranslation();

  const languages = [
    { value: 'en', label: 'EN' },
    { value: 'ar', label: 'AR' },
    { value: 'tr', label: 'TR' },
  ];

  const storedLang = Cookies.get("i18next");
  const [currentLang, setCurrentLang] = useState(storedLang || 'en');

  useEffect(() => {
      if (storedLang && storedLang !== currentLang) {
          setCurrentLang(storedLang);
          i18n.changeLanguage(storedLang);
      }
      // eslint-disable-next-line
      document.body.setAttribute('dir', storedLang === 'ar' ? 'rtl' : 'ltr');
  }, [storedLang]);
  

  const handleChangeLang = (selectedLang) => {
      setCurrentLang(selectedLang.value);
      i18n.changeLanguage(selectedLang.value);
      Cookies.set("i18next", selectedLang.value);
      closeMenu();
  };

  const [menuOpen, setMenuOpen] = useState(false);

  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);

  };
  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add('menu-open');
      document.body.classList.remove('menu-closed');
    } else {
      document.body.classList.remove('menu-open');
      document.body.classList.add('menu-closed');
    }

    return () => {
      document.body.classList.remove('menu-open');
      document.body.classList.add('menu-closed');
    };
  }, [menuOpen]);

  return (
      <>
        <header>
          <div className='h-[80px] max-w-[1280px] mx-auto hidden md:block'>
            <div className="flex gap-[40px] items-end mt-[15px] mx-[15px]">
              <Link to="/"><img src={logo} alt="" /></Link>
                <Dropdown
                  options={languages}
                  onChange={handleChangeLang}
                  placeholder="EN"
                  value={languages.find(lang => lang.value === currentLang)}
                />
                <Link
                    to="/services"
                    className='font-normal text-[16px] inter textcolor'
                >
                    {t('header.1')}
                </Link>
                <Link
                    to="/about"
                    className='font-normal text-[16px] inter textcolor'
                >
                    {t('header.2')}
                </Link>
                <Link
                    to="/cars"
                    className='font-normal text-[16px] inter textcolor'
                >
                    {t('header.3')}
                </Link>
            </div>
          </div>
          <div className="fixed top-0 z-50 bg-white w-full flex justify-between h-[60px] md:hidden">
            <div className="flex items-end mx-[20px]">
              <Link to="/"><img src={logo} alt="" className="w-[100px]"/></Link>
            </div>
            <div className="flex mt-[25px] mx-[20px]">
                <Dropdown
                    options={languages}
                    placeholder="EN"
                    onChange={handleChangeLang}
                    value={languages.find(lang => lang.value === currentLang)}
                    className="ml-auto mx-[20px]"
                />
                <MobileMenu/>
            </div>
          </div>
        </header>
      </>
  );
}

export default Header;